(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1245;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');


  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) !== null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
    window.HEADER_HEIGHT = $HEADER.outerHeight();
  });

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = function() {
    return WINDOW_WIDTH > DESKTOP_WIDE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  // ---------
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: true
      },
      $mainRotator;


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__'
    },
    'number-mask': {
      mask: '9{*}',
      greedy: false
    },
    'float-mask': {
      mask: '9{*}[(.|,)9{1,2}]',
      greedy: false
    }
  });
  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');

  // Number
  $('.js-number-mask').inputmask('number-mask');

  // Float number
  $('.js-float-mask').inputmask('float-mask');

  // Not empty
  $('.js-not-empty, .js-not-empty-with-trigger').on('blur', function() {
    var $element = $(this),
        value = $element.val(),
        defaultValue = $element.data('defaultValue');

    if( !$.isNumeric(defaultValue) )
      defaultValue = 1;

    if( !value )
      $element.val(defaultValue);

    if( $element.hasClass('js-not-empty-with-trigger') )
      $element.trigger('change');
  });
  // Minimum numeric value
  $('.js-min-value, .js-min-value-with-trigger').on('blur', function() {
    var $element = $(this),
        value = $element.val(),
        minValue = $element.data('min');

    if( !$.isNumeric(minValue) )
      minValue = 1;

    if( parseFloat(value) < parseFloat(minValue) )
      $element.val(minValue);

    if( $element.hasClass('js-min-value-with-trigger') )
      $element.trigger('change');
  });


  $WINDOW
    .on('load', function() {
      $DOCUMENT.trigger('headerMenuEvents');
    })
    .trigger('resize');


  $DOCUMENT
    .ready(function() {
      $mainRotator = $('#main-rotator');

      $DOCUMENT
        .trigger('initEconomyCalcForm')
        .trigger('filterPortfolioByService')
        .trigger('initSelectric');

      $WINDOW
        .on('hashchange', function() {
          $DOCUMENT.trigger('filterPortfolioByService');
        })
        .on('resize', function() {
          $DOCUMENT
            .trigger('headerMenuEvents')
            .trigger('rotatorImagesLoad');
        });


      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Main rotator
      // ------------
      $mainRotator
        .one('init', function() {
          $DOCUMENT.trigger('rotatorImagesLoad');
        })
        .slick($.extend({}, slickSettings, {
          fade: true,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: '0px',
          slide: '.main-slide-container',
          appendDots: '#main-rotator-dots',
          draggable: false
        }));


      // Portfolio rotator
      // ---------------
      $('.portfolio-rotator').each(function() {
        var $dots = $(this).find('.portfolio-rotator-dots');

        $(this).find('.portfolio-block-container .list-view')
          .on('init', function(e, slick) {
            slick.$slides.eq(0).addClass('slick-current-fix');
          })
          .on('beforeChange', function(e, slick, currentSlide, nextSlide) {
            if( (currentSlide > nextSlide && (nextSlide !== 0 || currentSlide === 1)) || (currentSlide === 0 && nextSlide === slick.slideCount - 1) ) {
              $dots.addClass('invert-fix');
            }
          })
          .on('afterChange', function() {
            $dots.removeClass('invert-fix');
          })
          .on('animateSlide', function(e, slick) {
            slick.$slideTrack.children().removeClass('slick-current-fix').filter('[data-slick-index="'.concat(slick.currentSlide, '"], [data-slick-index="', slick.currentSlide + slick.slideCount, '"], [data-slick-index="', slick.currentSlide - slick.slideCount, '"]')).addClass('slick-current-fix');
          })
          .slick($.extend({}, slickSettings, {
            arrows: false,
            dots: true,
            slide: '.list-view-item',
            slidesToScroll: 1,
            slidesToShow: 3,
            swipeToSlide: true,
            appendDots: $dots/*,
            responsive: [
              {
                breakpoint: WIDE_DESKTOP_WIDTH,
                settings: {
                  autoplay: true,
                  adaptiveHeight: false,
                  slidesToShow: 2
                }
              },
              {
                breakpoint: LANDSCAPE_MOBILE_WIDTH,
                settings: {
                  autoplay: false,
                  adaptiveHeight: true,
                  slidesToShow: 1
                }
              }
            ]*/
          }));
      });


      // Portfolio filter
      // ----------------
      $('.portfolio-filtering .list-view').isotope({
        itemSelector: '.list-view-item',
        layoutMode: 'fitRows',
        percentPosition: true,
        transitionDuration: '0.5s',
        containerStyle: null
      });
    })

    // Init selectric
    // --------------
    .on('initSelectric', function() {
      $('select').selectric({
        disableOnMobile: false,
        nativeOnMobile: true,
        openOnHover: false,
        hoverIntentTimeout: 0,
        inheritOriginalWidth: false
      });
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if( IS_DESKTOP_WIDTH() ) {
        if( !$HEADER_MENU.hasClass('is-desktop-events') ) {
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');
        }
      }
      else {
        if( !$HEADER_MENU.hasClass('is-mobile-events') ) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest($HEADER_MENU).length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > ul a, .header-menu-container > ul span, .header-menu-container > ul .dropmarker', function(e) {
              e.preventDefault();

              var $self = $(this),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              $parent.siblings().removeClass('is-hovered').children('ul').slideUp(200);

              if( isWithDropdown ) {
                if( isOnHover ) {
                  if( $self.prop("tagName").toUpperCase() === 'A' )
                    location.href = $self.prop('href');
                  else
                    $parent.removeClass('is-hovered').children('ul').slideUp(200);
                } else {
                  $parent.addClass('is-hovered').children('ul').slideDown(200);
                }
              } else {
                if( $self.prop("tagName").toUpperCase() === 'A' )
                  location.href = $self.prop('href');
              }
            });
        }
      }
    })

    // Load rotator images
    .on('rotatorImagesLoad', function() {
      if( $mainRotator.length ) {
        var dataKey = 'imageBig';

        if( IS_MOBILE_WIDTH() )
          dataKey = 'imageSmall';
        else if( !IS_DESKTOP_WIDTH() )
          dataKey = 'imageMedium';

        $mainRotator.find('.main-slide-image > div > span').each(function() {
          var $self = $(this),
              image = $self.data(dataKey);

          $('<img/>').attr('src', image).on('load', function() {
            $(this).remove();

            $self
              .css('background-image', 'url(\''.concat(image, '\')'))
              .parent().addClass('no-blur');
          });
        });
      }
    })

    // Init economy calc form
    // ----------------------
    .on('initEconomyCalcForm', function() {
      var $form = $('#economy-calc-form');

      if( $form.length ) {
        var $mileageInput = $form.find('#economy-calc-mileage'),
            mileageValue = parseInt($mileageInput.val()) || 20000;

        $form.find('#economy-calc-mileage-slider > div').slider({
          classes: {
            'ui-slider': '',
            'ui-slider-handle': '',
            'ui-slider-range': ''
          },
          range: false,
          value: mileageValue,
          step: 50,
          min: parseInt($mileageInput.data('min')) || 100,
          max: parseInt($mileageInput.data('max')) || 100000,
          create: function(e) {
            $(e.target).find('.ui-slider-handle').html('<span><span>' + number_format(mileageValue) + '</span></span>');
          },
          slide: function(e, ui) {
            $(ui.handle).html('<span><span>' + number_format(ui.value) + '</span></span>');
            $mileageInput.val(ui.value).trigger('change');
          }
        });
      }
    })
    // Economy calc form events
    // ------------------------
    .on('submit.economyCalcFormSubmit', '#economy-calc-form', function(e) {
      e.preventDefault();
    })
    .on('click.economyCalcOrderFormShow', '#economy-calc-form button', function(e) {
      e.preventDefault();

      $.fancybox.open({
        src: '#economy-calc-order-form',
        type: 'inline'
      });
    })
    .on('change.economyCalcFormChange', '#economy-calc-form', function(e) {
      e.preventDefault();

      var $form = $(this),
          $fuelPrice = $form.find('#economy-calc-fuel-price'),
          fuelPriceValue = parseFloat($fuelPrice.val().replace(',', '.')) || parseFloat($fuelPrice.data('defaultValue')),
          $gasPrice = $form.find('#economy-calc-gas-price'),
          gasPriceValue = parseFloat($gasPrice.val().replace(',', '.')) || parseFloat($gasPrice.data('defaultValue')),
          $fuelConsumption = $form.find('#economy-calc-fuel-consumption'),
          fuelConsumptionValue = parseFloat($fuelConsumption.val().replace(',', '.')) || parseFloat($fuelConsumption.data('defaultValue')),
          $mileage = $form.find('#economy-calc-mileage'),
          mileageValue = parseInt($mileage.val()) || parseInt($mileage.data('defaultValue')),
          fuelTotalCost = mileageValue * fuelConsumptionValue * fuelPriceValue / 100,
          gasTotalCost = mileageValue * fuelConsumptionValue * gasPriceValue / 100,
          economy = Math.ceil(fuelTotalCost - gasTotalCost),
          $orderForm = $('#economy-calc-order-form');

      $form.find('#fuel-total-cost').html('<span>'.concat(number_format(fuelTotalCost, 2), '</span> руб.'));
      $form.find('#gas-total-cost').html('<span>'.concat(number_format(gasTotalCost, 2), '</span> руб.'));
      $form.find('#economy').html('<span>'.concat(number_format(economy), '</span> руб.?'));

      $orderForm.find('#CallbackEconomyCalc_fuelPrice').val(number_format(fuelPriceValue, 2).concat(' руб./л'));
      $orderForm.find('#CallbackEconomyCalc_gasPrice').val(number_format(gasPriceValue, 2).concat(' руб./л'));
      $orderForm.find('#CallbackEconomyCalc_fuelConsumption').val(number_format(fuelConsumptionValue, 2).concat(' л'));
      $orderForm.find('#CallbackEconomyCalc_mileage').val(number_format(mileageValue, 2).concat(' км'));
      $orderForm.find('#CallbackEconomyCalc_economy').val(number_format(economy, 2).concat(' руб.'));
      $orderForm.find('#economy-order-value').text(number_format(economy, 2));
    })

    // Portfolio filter
    // ----------------
    .on('click.portfolioFilterServiceLink', '.portfolio-service-links div', function(e) {
      e.preventDefault();

      var filterValue = $(this).toggleClass('active').parent().children('.active').map(function() { return this.getAttribute('data-filter'); }).get().join(',');

      if( !!filterValue )
        location.hash = encodeURIComponent( filterValue );
      else
        location.hash = 'all';
    })
    .on('change.filterPortfolioByModel', '.portfolio-model-select select', function(e) {
      e.preventDefault();

      location.href = $(this).val();
    })
    .on('filterPortfolioByService', function() {
      if( $('.portfolio-filtering').length ) {
        var filterValue = decodeURIComponent( location.hash.slice(1) ) || 'all',
            $portfolioGrid = $('.portfolio-filtering .list-view');

        $('.portfolio-service-links div').removeClass('active').filter(filterValue.split(',').map(function(item) { return '[data-filter="'.concat(item, '"]'); }).join(',')).addClass('active');

        if( filterValue === 'all' ) {
          $portfolioGrid.isotope({ filter: '*'});
        } else {
          $portfolioGrid.isotope({ filter: filterValue.split(',').map(function(item) { return '[data-service*="'.concat(item, '"]'); }).join(',')});
        }
      }
    })
    // Portfolio popular sections show/hide
    // ------------------------------------
    .on('click.sectionsPopularLink', '.portfolio-sections-popular-link span', function(e) {
      e.preventDefault();

      var $self = $(this),
          $target = $self.parent().parent().children('.portfolio-sections-container');

      if( !$self.hasClass('active') ) {
        $self.addClass('active').text($self.data('textShow'));
        $target.addClass('not-popular-visible');
      }
      else {
        $self.removeClass('active').text($self.data('textHide'));
        $target.removeClass('not-popular-visible');
      }
    })
    // Portfolio other sections show
    // -----------------------------
    .on('click.sectionsOtherLink', '.portfolio-sections-other-link span', function(e) {
      e.preventDefault();

      $('.portfolio-sections-other').slideToggle(200);
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div:first-child', function(e) {
      e.preventDefault();

      var $self = $(this).parent();

      if( !$self.hasClass('active') ) {
        $self.addClass('active');

        $self.children('div + div').slideDown(200);
      }
      else{
        $self.removeClass('active');

        $self.children('div + div').slideUp(200);
      }
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = ($lnk.data('scrollOffset') || 0);

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      autoFocus: false,
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    }
  });
})();